// --- Common

export const CONTACT = 'contact';
export const CONTACT_SUCCESS = 'success';

// --- Public pages
export const ROOT = '/';

// Collective
export const COLLECTIVE = 'collective';
export const COLLECTIVE_SLUG_PARAM = '[collectiveSlug]';
export const COLLECTIVE_ROOT = `/${COLLECTIVE}/${COLLECTIVE_SLUG_PARAM}`;

// Case studies
export const CASE_STUDY = 'case-study';
export const CASE_STUDY_SLUG_PARAM = '[caseStudySlug]';
export const CASE_STUDY_PATH = `/${COLLECTIVE}/${COLLECTIVE_SLUG_PARAM}/${CASE_STUDY}/${CASE_STUDY_SLUG_PARAM}`;

// Contact
export const CONTACT_PATH = `${COLLECTIVE_ROOT}/${CONTACT}`;
export const CONTACT_SUCCESS_PATH = `${COLLECTIVE_ROOT}/${CONTACT}/${CONTACT_SUCCESS}`;

// --- Marketplace
export const MARKETPLACE = 'browse';
export const LANGUAGE_PARAM = '[language]';
export const MARKETPLACE_ROOT = `/${COLLECTIVE}/${MARKETPLACE}/${LANGUAGE_PARAM}`;

// Public profile
export const PUBLIC_PROFILE = 'profile';
export const PUBLIC_PROFILE_ROOT = `/${PUBLIC_PROFILE}`;

// Public jobs
export const PUBLIC_JOB = 'job';
export const PUBLIC_JOB_ROOT = `/${PUBLIC_JOB}`;

// Public search
export const PUBLIC_SEARCH = 'search';
export const SEARCH_ROOT = `/${PUBLIC_SEARCH}/${LANGUAGE_PARAM}`;

// Contact
export const MARKETPLACE_CONTACT_PATH = `${MARKETPLACE_ROOT}/${CONTACT}`;
export const MARKETPLACE_CONTACT_SUCCESS_PATH = `${MARKETPLACE_ROOT}/${CONTACT}/${CONTACT_SUCCESS}`;

// Project
export const PROJECT = 'project';
export const PROJECT_SUCCESS = 'success';
export const PROJECT_BOOK_A_CALL = 'book-a-call';
export const MARKETPLACE_PROJECT_PATH = `${MARKETPLACE_ROOT}/${PROJECT}`;
export const MARKETPLACE_PROJECT_SUCCESS_PATH = `${MARKETPLACE_ROOT}/${PROJECT}/${PROJECT_SUCCESS}`;
export const MARKETPLACE_PROJECT_BOOK_A_CALL_PATH = `${MARKETPLACE_ROOT}/${PROJECT}/${PROJECT_BOOK_A_CALL}`;

// Freelance
export const FREELANCE = 'freelance';
export const FREELANCE_ROOT = `/${FREELANCE}`;
export const FREELANCE_POSITION_PARAM = '[position]';
export const FREELANCE_POSITION_PATH = `${FREELANCE_ROOT}/${FREELANCE_POSITION_PARAM}`;
export const FREELANCE_LOCATION_PARAM = '[location]';
export const FREELANCE_LOCATION_PATH = `${FREELANCE_ROOT}/${FREELANCE_POSITION_PARAM}/${FREELANCE_LOCATION_PARAM}`;
